/* overrides Nextjs __next div */
#__next {
  height: 100%;
  width: 100%;
  position: fixed;
}

.DateRangePicker_picker {
  z-index: var(--chakra-zIndices-popover);
}
